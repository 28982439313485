/* eslint-disable vue/no-template-key */
<template>
  <div class="home">
    <div class="guize" @click="guize">规则</div>
    <div class="content">
      <div class="top">
        <div>
          <p>本周已赚取：</p>
          <p>{{ tryMoney }}<span>元</span></p>
        </div>
      </div>
      <div class="times" v-if="showDue">
        <p>{{dueTips.split(":")[0]}}</p>
        时
        <p>{{dueTips.split(":")[1]}}</p>
        分后未领取奖励将失效，请尽快领取
      </div>
      <div class="list" v-for="(item, index) in tasks" :key="index">
        <div class="list-left">
          <img
            src="https://ppyos.xijiuyou.com/202202/money-icon-h.png"
            alt=""
          />
          <div>
            <p>+{{ item.awardMoney }} <span>元</span></p>
            <p>{{ item.note }}</p>
          </div>
        </div>
        <div
          @click="game(item,index)"
          :class="
            item.status === 1 || item.status === 2
              ? 'btn'
              : item.status === -1
              ? 'btn-suc'
              : 'btn-l'
          "
        >
          {{
            item.status === 1
              ? "已领取"
              : item.status === -1
              ? "去赚取"
              : item.status === 2
              ? "领取异常"
              : "领取奖励"
          }}
        </div>
      </div>
      <!-- <div class="list">
        -1 待完成

        <div class="list-left">
          <img
            src="https://ppyos.xijiuyou.com/202202/money-icon-h.png"
            alt=""
          />
          <div>
            <p>+2 <span>元</span></p>
            <p>每周赚钱频道赚得20元</p>
          </div>
        </div>
        <div class="btn-l">已领取</div>
      </div> -->
      <!-- <div class="list">
        <div class="list-left">
          <img
            src="https://ppyos.xijiuyou.com/202202/money-icon-h.png"
            alt=""
          />
          <div>
            <p>+2 <span>元</span></p>
            <p>每周赚钱频道赚得20元</p>
          </div>
        </div>
        <div class="btn-suc">已领取</div>
      </div> -->
    </div>
    <img
      class="bottom"
      src="https://ppyos.xijiuyou.com/202202/flower-banner-black.png"
      alt=""
    />
  </div>
</template>

<script>
import list from "../api/List";
// import { Toast } from "vant";
export default {
  name: "Home",
  data() {
    return {
      tryMoney: "",
      showDue: true,
      tasks: [],
      dueTips:"",
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    game(item,index) {
      if (item.status === -1) {
        window.location.href = "paopaoyu://app/page/main/index?tab=game&arg=-2,cpl";
      } else if (item.status === 1) {
        return false;
      } else if (item.status === 0) {
        list
          .challengedraw({
            recordId: item.recordId,
            taskId: item.taskId,
          })
          .then(() => {
            this.tasks[index].status = 1;
          });
      }
    },
    list() {
      list.challengeinfo().then((res) => {
        console.log(res);
        this.tryMoney = res.data.tryMoney;
        this.showDue = res.data.showDue;
        this.tasks = res.data.tasks;
        this.dueTips = res.data.dueTips;
      });
    },
    guize() {
      this.$router.push({
        name: "Challenge",
      });
    },
  },
};
</script>
<style scoped lang='less'>
.home {
  width: 100%;
  min-height: 100vh;
  background: url("https://ppyos.xijiuyou.com/202202/flower-banner.png")
    no-repeat;
  background-size: 100%;
  background-color: rgba(131, 174, 103, 1);
  position: relative;
  padding-top: 155px;
  padding-bottom: 50px;
}
.bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}
.times {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #444444;
  margin-bottom: 20px;
  p {
    width: 25px;
    height: 25px;
    background: #444444;
    border-radius: 6px;
    font-size: 16px;
    font-family: DINOffcPro-Black, DINOffcPro;
    font-weight: 900;
    color: #ffffff;
    padding-top: 4px;
    margin-right: 5px;
    text-align: center;
  }
  p:nth-child(2) {
    margin-left: 5px;
  }
}
.content {
  width: calc(100% - 24px);
  margin: 0 auto;
  background: #ffffff;
  border-radius: 15px;
  padding: 1px 0 1px;
  position: relative;
  z-index: 1;
  .top {
    margin-top: -50px;
    width: 100%;
    background: url("https://ppyos.xijiuyou.com/202202/task-list.png")
      no-repeat;
    background-size: 100%;
    height: 67px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 13px;
    div {
      width: 140px;
      height: 45px;
      background: #ffd971;
      box-shadow: inset 0px -2px 6px 0px rgba(255, 169, 86, 0.5);
      border-radius: 24px;
      margin-right: 10px;
      margin-top: 22px;
      box-sizing: border-box;
      text-align: center;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      p:nth-child(1) {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #985400;
        margin: 0;
      }
      p {
        width: 100%;
        text-align: center;
        font-size: 18px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #985400;
        margin-top: -9px;
        span {
          font-size: 16px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #985400;
        }
      }
    }
  }
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 12px;
  margin-bottom: 26px;
  .btn {
    width: 84px;
    height: 34px;
    background: #eeeeee;
    border-radius: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #999999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-l {
    width: 84px;
    height: 34px;
    background: linear-gradient(270deg, #ff5e78 0%, #ffa15f 100%);
    border-radius: 17px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .btn-suc {
    width: 84px;
    height: 34px;
    background: #ffdbdb;
    border-radius: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #ff5675;
  }
  .list-left {
    display: flex;
    align-items: center;
    img {
      width: 38px;
      height: 38px;
      margin-right: 10px;
    }
    div {
      font-size: 13px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      p:nth-child(1) {
        font-size: 20px;
        font-family: DINOffcPro-Medi, DINOffcPro;
        font-weight: normal;
        color: #ff5675;
        span {
          font-size: 17px;
        }
      }
    }
  }
}
.guize {
  position: absolute;
  right: 0;
  z-index: 10;
  top: 40px;
  width: 20px;
  height: 42px;
  background: #353434;
  border-radius: 8px 0px 0px 8px;
  backdrop-filter: blur(10px);
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  line-height: 21px;
}
</style>
