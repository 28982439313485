/* eslint-disable no-undef */
/**
 *http响应拦截器
 */
import axios from "axios";
// import router from "../router/index"; //路由
import { Toast } from "vant";
// console.log(router);
if(process.env.NODE_ENV === 'development'){
  //axios.defaults.baseURL = 'https://www.umiblog.cn/'
  axios.defaults.baseURL = 'https://ppy.ippyu.com/'
  //axios.defaults.baseURL = 'https://testppy.ippyu.com/'
  } else {
    axios.defaults.baseURL = config.baseUrl
  }
///request拦截器
axios.interceptors.request.use(
   (req) => {
    let data ={};
    try {
      const getMsaOaid = window.android.getMsaOaid();
      const getKeyCode = window.android.getKeyCode();
      const getDeviceId = window.android.getDeviceId();
      const phoneType = window.android.getPhoneType();
      const userId = window.android.getUserID();
      const getOSVersionCode = window.android.getOSVersionCode();
      
      data = {
        oaid: getMsaOaid,//
        keycode: getKeyCode,//getKeyCode
        deviceId:getDeviceId,//getDeviceId
        sdkInt:getOSVersionCode,//
        userId: userId,
        phoneType: phoneType,
      };
    } catch (error) {
      data={};
    }
    
    // const data = {
    //   oaid: '',//getMsaOaid
    //   keycode: "EA12D50D1376794008466F4EF2B3C487",//getKeyCode
    //   deviceId:"b83ca7fa-bf22-4a9e-b90c-b4661eefa2bd",//getDeviceId
    //   sdkInt:29,//getOSVersionCode
    //   userId: 138,
    //   packageName :"com.jiuyou.caibeiwan",
    //   phoneType: 2,
    // };
    req.params = Object.assign({}, data, req.params, {
      t: Math.random(),
    });
    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//response拦截器
axios.interceptors.response.use(
  (res) => {
    if(res.data.status!=0 && res.data.status!=60002){
      Toast(res.data.view)
      return Promise.reject(res.data.view);
    }else{
      return res.data;
    }
  },
  (error) => {
    //token失效返回401处理
    if (error.response.code == 401) {
      //刷新token
    }
    return Promise.reject(error.response.data); // 返回错误信息
  }
);
