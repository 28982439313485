export const DEFAULT_PACKAGE = "com.ppy.ppyw"

export const PACKAGE_NAME_MAP = {
  "com.ppy.ppyw": "泡泡鱼玩",
  "com.jiuyou.caibeiwan": "彩贝玩"
}

export default {
  getCurrentPackage(){
    try{
      return window.android.getPackageName()
    }catch(t){
      console.log(t)
    }
    return DEFAULT_PACKAGE
  }
}