import Vue from "vue";
import VueRouter from "vue-router";
import packageUtils, { PACKAGE_NAME_MAP } from "../utils/packageUtils";

import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/weekTask",
    name: "Home",
    component: Home,
    meta: {
      title: "周周挑战",
    },
  },
  {
    path: "/clockin",
    name: "clockin",
    component: () =>
      import( "../views/clockin/index.vue"),
    meta: {
      title: "早起打卡",
    },
  },
  {
    path: "/clockinguiz",
    name: "clockinguiz",
    component: () =>
      import( "../views/clockin/guize.vue"),
    meta: {
      title: "活动规则",
    },
  },
  {
    path: "/clocklist",
    name: "clocklist",
    component: () =>
      import( "../views/clockin/clocklist.vue"),
    meta: {
      title: "我的战绩",
    },
  },
  {
    path: "/",
    name: "game",
    component: () =>
      import("../views/Caibei/index.vue"),
    meta: {
      title: "小游戏",
    },
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import("../views/About.vue"),
    meta: {
      title: "活跃值明细",
    },
  },
  {
    path: "/Challenge",
    name: "Challenge",
    component: () =>
      import("../views/charges/Challenge.vue"),
    meta: {
      title: "规则",
    },
  },
  {
    path: "/viprule",
    name: "viprule",
    component: () =>
      import("../views/viprule.vue"),
    meta: {
      title: "会员规则",
    },
  },
  {
    path: "/Carveup",
    name: "Carveup",
    component: () =>
      import("../views/Carveup.vue"),
    meta: {
      title: "现金瓜分赛",
    },
  },
  {
    path: "/success",
    name: "success",
    component: () =>
      import("../views/charges/succ.vue"),
    meta: {
      title: "话费充值",
    },
  },
  {
    path: "/chargeslist",
    name: "chargeslist",
    component: () =>
      import("../views/charges/list.vue"),
    meta: {
      title: "话费充值记录",
    },
  },
  {
    path: "/majiang",
    name: "majiang",
    component: () =>
      import("../views/brand/majiang.vue"),
    meta: {
      title: "龙年新春摸麻将",
    },
  },//
  {
    path: "/majiangs",
    name: "majiangs",
    component: () =>
      import("../views/brand/majiangs.vue"),
    meta: {
      title: "龙年新春摸麻将",
    },
  },
  {
    path: "/brand",
    name: "brand",
    component: () =>
      import("../views/brand/brand.vue"),
    meta: {
      title: "我的牌",
    },
  },
  {
    path: "/brandexchange",
    name: "brandexchange",
    component: () =>
      import("../views/brand/exchange.vue"),
    meta: {
      title: "兑换记录",
    },
  },
  {
    path: "/mjrule",
    name: "mjrule",
    component: () =>
      import("../views/brand/guize.vue"),
    meta: {
      title: "规则",
    },
  },
  {
    path: "/charges",
    name: "charges",
    component: () =>
      import("../views/charges/index.vue"),
    meta: {
      title: "话费充值",
    },
  },
  {
    path: "/chargescaibei",
    name: "chargescaibei",
    component: () =>
      import("../views/charges/index.vue"),
    meta: {
      title: "话费充值",
    },
  },
  {
    path: "/list",
    name: "List",
    component: () =>
      import("../views/List.vue"),
    meta: {
      title: "周周冲榜",
    },
  },
  {
    path: "/ranking",
    name: "ranking",
    component: () =>
      import("../views/ranking.vue"),
    meta: {
      title: "我的排名奖励",
    },
  },
  {
    path: "/invitation",
    name: "invitation",
    component: () =>
      import("../views/invitation.vue"),
    meta: {
      title: "邀请好友",
    },
  },
  {
    path: "/download",
    name: "download",
    component: () =>
      import("../views/download.vue"),
    meta: {
      title: `快和我一起去${PACKAGE_NAME_MAP[packageUtils.getCurrentPackage()]}赚金！`,
    },
  },
  {
    path: "/game",
    name: "games",
    component: () =>
      import("../views/Caibei/index.vue"),
    meta: {
      title: "小游戏",
    },
  },
  {
    path: "/caibei",
    name: "caibei",
    component: () =>
      import("../views/game/index.vue"),
    meta: {
      title: "小游戏",
    },
  },
  {
    path: "/caibeirecord",
    name: "caibeirecord",
    component: () =>
      import("../views/Caibei/record.vue"),
    meta: {
      title: "彩贝明细",
    },
  },
  {
    path: "/caibeiexchange",
    name: "caibeiexchange",
    component: () =>
      import("../views/Caibei/exchange.vue"),
    meta: {
      title: "彩贝兑换",
    },
  },
  {
    path: "/videoVip",
    name: "videoVip",
    component: () =>
      import("../views/Caibei/videoVip.vue"),
    meta: {
      title: "商品详情",
    },
  },
  // {
  //   path: "/game",
  //   name: "game",
  //   component: () =>
  //     import("../views/game/index.vue"),
  //   meta: {
  //     title: "小游戏",
  //   },
  // },
  {
    path: "/record",
    name: "record",
    component: () =>
      import("../views/game/record.vue"),
    meta: {
      title: "红包记录",
    },
  },
  {
    path: "/arena",
    name: "arena",
    component: () =>
      import("../views/game/arena.vue"),
    meta: {
      title: "擂台赛",
    },
  },
  {
    path: "/reward",
    name: "reward",
    component: () =>
      import("../views/game/reward.vue"),
    meta: {
      title: "赏金小游戏",
    },
  },
  {
    path: "/exchange",
    name: "exchange",
    component: () =>
      import("../views/exchange/index.vue"),
    meta: {
      title: "活跃值兑换",
    },
  },
  {
    path: "/Active",
    name: "Active",
    component: () =>
      import("../views/exchange/Active.vue"),
    meta: {
      title: "活跃值兑换记录",
    },
  },
  {
    path: "/cashing",
    name: "cashing",
    component: () =>
      import("../views/exchange/cashing.vue"),
    meta: {
      title: "兑换中心",
    },
  },
  {
    path: "/Activevalue",
    name: "Activevalue",
    component: () =>
      import("../views/exchange/Activevalue.vue"),
    meta: {
      title: "兑换记录",
    },
  },
  {
    path: "/loading",
    name: "loading",
    component: () =>
      import("../views/exchange/loading.vue"),
    meta: {
      title: "邀请好友",
    },
  },
  {
    path: "/caibeivip",
    name: "caibeivip",
    component: () =>
      import("../views/caibeivip.vue"),
    meta: {
      title: `${PACKAGE_NAME_MAP[packageUtils.getCurrentPackage()]}VIP`,
    },
  },
  {
    path: "/detailed",
    name: "detailed",
    component: () =>
      import("../views/exchange/detailed.vue"),
    meta: {
      title: "邀请好友明细",
    },
  },
  {
    path: "/wxrpskn",
    name: "wxrpskn",
    component: () =>
      import( "../views/wxredpackskn/sknrcv.vue"),
    meta: {
      title: "领取微信红包封面",
    },
  },
  {
    path: "/cover",
    name: "cover",
    component: () =>
      import( "../views/cover/index.vue"),
    meta: {
      title: "邀请3位好友即可领取微信红包封面！",
    },
  },//myvoucher
  {
    path: "/myvoucher",
    name: "myvoucher",
    component: () =>
      import( "../views/welfare/myvoucher.vue"),
    meta: {
      title: "我的卡券",
    },
  },
  {
    path: "/myprize",
    name: "myprize",
    component: () =>
      import( "../views/welfare/myprize.vue"),
    meta: {
      title: "我的奖品",
    },
  },
  {
    path: "/welfare",
    name: "welfare",
    component: () =>
      import( "../views/welfare/welfare.vue"),
    meta: {
      title: "限时福利",
    },
  },
  {
    path: "/life",
    name: "life",
    component: () =>
      import( "../views/life.vue"),
    meta: {
      title: "本地生活",
    },
  },
  {
    path: "/Overlord",
    name: "Overlord",
    component: () =>
      import( "../views/Overlord/home.vue"),
    meta: {
      title: "米粒饭票",
    },
  },
  {
    path: "/mycover",
    name: "mycover",
    component: () =>
      import( "../views/cover/mycover.vue"),
    meta: {
      title: "我的红包封面",
    },
  },
  {
    path: "/Swipers",
    name: "Swipers",
    component: () =>
      import( "../views/cover/swipers.vue"),
    meta: {
      title: "我的红包封面",
    },
  },
  {
    path: "/payTest",
    name: "PayTest",
    component: () =>
      import( "../views/payTest.vue"),
    meta: {
      title: "支付测试",
    },
  },
  {
    path:"/turntable",
    name: "turntable",
    component: () =>
      import( "../views/turntable.vue"),
    meta: {
      title: "幸运转盘抽福利",
    },
  }
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});
export default router;
