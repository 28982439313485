import axios from "axios";
const list = {
  challengeinfo() {
    //挑战任务首页
    return axios.get("/api/ppy/usercenter/challenge/info");
  },
  challengedraw(params) {
    //挑战任务首页
    return axios.get("/api/ppy/usercenter/challenge/draw",{
        params
    });
  },
};
export default list;
