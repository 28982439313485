<template>
  <div
    id="app"
    :style="{'min-height':height + 'px'}"
  >
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  data () {
    return {
      height: 0
    }
  },
  mounted () {
    this.height = window.innerHeight
    window.addEventListener('resize', this.resize)
  },
  methods: {
    resize () {
      this.height = window.innerHeight
    }
  }
}
</script>

<style>
.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}


body,html{
  padding: 0 0;
  margin: 0 0;
  background: rgba(246, 246, 246, 1);
}

html, body, div, p, h1, h2, h3, h4, h5, h6, ul, li {
    padding: 0;
    margin: 0;
}

#app{
  background: #f6f6f6;
  width: 100%;
  position: relative;
}

* {
  box-sizing: border-box;
}

@media screen and (min-width: 750px) {
  #app {
    width: 375px;
    margin-left: auto;
    margin-right: auto;
  }
  body{
    background: #eee;
  }
}
</style>
<style  rel="stylesheet/less">
  @font-face {
  font-family: "DINOffcPro-Black";
  src: url('https://ppyos.xijiuyou.com/fonts/DINOffcPro-Black.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINOffcPro-Bold";
  src: url('https://ppyos.xijiuyou.com/202212/DINOffcPro-Bold.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "DINOffcPro-Medi";
  src: url('https://ppyos.xijiuyou.com/fonts/DINOffcPro-Medi.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "v16-front";
  src: url('https://ppyos.xijiuyou.com/202205/v16-front.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "SourceHanSansCN-Bold";
  src: url('https://ppyos.xijiuyou.com/fonts/SourceHanSansCN-Bold.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "AlimamaShuHeiTi-Bold";
  src: url('https://ppyos.xijiuyou.com/202212/AlimamaShuHeiTi-Bold.woff');
  font-weight: normal;
  font-style: normal;
}
body{
  overflow: auto!important;
}
</style>