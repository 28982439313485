import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vant,{ Stepper ,List ,Swipe, SwipeItem,Popup,ActionSheet} from "vant";
import './axios/index'
import axios from "axios";
import 'vant/lib/index.css';
Vue.use(Stepper);
Vue.use(List);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Popup);
Vue.use(ActionSheet);
Vue.use(Vant);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  Vant,
  axios,
  render: (h) => h(App),
}).$mount("#app");
